/* Container styling */
.cont {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  /* Search Bar */
  .search-container {
    margin-bottom: 20px;
  }
  
  .search-bar {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* People List Styling */
  .people-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .person-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Person Header */
  .person-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-id {
    font-size: 12px;
    color: #666;
  }
  
  /* Status badge */
  .status-badge {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    width: 100px;
    background-color: #f44336;
  }
  
  .status-ongoing {
    background-color: #ffecb3;
    color: #ff9800;
  }
  
  .status-pending {
    background-color: #ffe0e0;
    color: #f44336;
  }
  
  .status-success {
    background-color: #e0f2f1;
    color: #4caf50;
  }
  
  /* Date Info */
  .date-info {
    font-size: 12px;
    color: #999;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .person-card {
      padding: 15px;
    }
    
    .person-header h3 {
      font-size: 16px;
    }
  
    .search-bar {
      font-size: 14px;
    }
  }
  