:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

a {
  text-decoration: none !important;
  color: black !important;
}

h1 {
  font-size:  25px !important;
 
}
h4 {
  font-size: 18px !important;
}

.Imagesstudyicon {
  width: 100%;
  height: 200px;
}

.mainSectionsec {
  font-size: 20px;
  color: black;
  font-weight: 500;
  text-align: center;
}

#partner .card {
  width: 100% !important;
  height: 375px !important;

  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.card::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Imagessides {
  width: 100%;
  height: 750px;
}

.Imagespassport {
  width: 100%;
  height: 364px;
}

/* // journey */

.bgstyle {
  background-image: url("../public/jurneybg.png");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
}


.filling {
  background-image: url("../public/withoutbg.png");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  height:50vh;
  padding-top: 100PX;

 
  
}

.sidehum {
  width: 10px;
  height: 40px;
}

.Imagessidehide {
  width: 100%;
}

.initalimg {
  height: 595px;
  width: 100%;
}

.disandfundimg {
  width: 100%;
  height: 270px;
}
.dreamimg {
  width: 100%;
  height: 150px;
}
/* Custom CSS for slick carousel arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.image-container {
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dreamimg {
  width: 100%;
  height: 150px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}



.image-container:hover .overlay {
  opacity: 1;
}

.title {
  font-size: 1.2em;
  text-align: center;
  margin: 0;
}

.img-fluidd {
  width: 100%;
  height: 400px;
  object-fit: fill;
}
.img-job {
  width: 100%;
 
 
  object-fit: contain;
}
.carousel-container {
  padding: 0px !important;

}

.carouselitem {
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  margin: 15px; /* Space between items */
}

.carouselitem:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.carousel-img {
  width: 100%;
  height: 250px;
  
  object-fit: cover;

}


.carousel-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  
  text-align: center;
  
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(0);
}

.carouselitem:hover .carousel-title {
  
  transform: translateX(100%);
}


.img-fluiddparr {
  width: 100%;
  object-fit: contain;
  height: 120px;
}

.lefticon {
  width: 30px;
  height: 30px;
}

.Testmoniimg {
  width: 100%;
  height: 830px;
}

.carousel-wrapper {
  width: 100%;

  margin-top: 120px;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slides {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slidepartner {
  display: flex;

  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.slide-content {
}

.slide-content h3 {
  margin: 10px 0;
}

.slide-content p {
  margin: 0;
}

.testcaro {
  width: 100px;
  height: 200px;
}

.heading {
  width: 60%;
  height: 70px;
}

.topcent {
  margin-top: 120px !important;
}

.partnerbg {
  background-image: url("../public/partnerbg.png");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
}

.Bannerbg {
  background-image: url("../public/BannerImg.jpg");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: top; /* Center the image */
  width: "100%";
}

.slidesBannerbg {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bannerstyle {
  padding-top: 700px;
  padding-right: 100px;
}

.carousel-wrapperr {
  width: 100%;
}

.slider {
  background-image: url("../public/Studyvisa.jpg");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  padding-top: 120px;
  justify-content: center;
  align-items: center;
}
.slider2 {
  background-image: url("../public/Career.jpg");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  padding-top: 120px;
  justify-content: center;
  align-items: center;
}


.sliderServices {
  background-image: url("../public/servicesbg.jpg");
  background-size: cover; /* Ensure the image covers the entire element */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  width: 100%;
 
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.Severour{
  color:#24415f;
  font-size: 50px !important;
 
  
 }

.banner-contentr {
  color: white;

  border-radius: 10px;
}

.d-flexr {
  display: flex;
}

.gapr-3 {
  /* Adjust the gap between elements */
}
.headername {
  color: #24415f !important;
  line-height: 40px;
  font-size: 30px;
  font-weight: bold;
}

.buttonstyleapply {
  width: 40%;
  height: 55px;
  padding: 10px 0px 1px 0px;
  border-radius: 20px;
  background-color: #24415f;
  /* align-items: center;
  display: flex;
  justify-content: center;
  height: 40px; */
  margin-top: 20px;
}

/* Header */

.logstyle {
  width: 150px;
  height: 100px;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

/* Add this CSS to your stylesheet */
/* Header.css */
/* Header.css */
.header-menu {
  position: relative;
}

.navstylee {
  font-size: 20px;
  font-weight: 700;
  color: #24415f !important;
  cursor: pointer;
  white-space: nowrap;
}
.navstylee:hover {
  transition: ease-in-out;

  color: rgb(5, 47, 232) !important;
}

.header-menu .services-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  top: 100%; /* Position below the services text */
  left: 0;
}

.header-menu:hover .services-menu {
  display: block;
}

.services-menu p {
  margin: 0;
  padding: 5px 10px;
}

.services-menu p:hover {
  background-color: #f0f0f0;
}
.buttonstylcontact {
  width: 20%;
  padding: 20px 5px 5px 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  
  background: linear-gradient(80deg, #24415f 41%,#07b5cd 85%);
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
}

.buttonstylcontact:hover {
  background: linear-gradient(80deg, #07b5cd 41%, #24415f 85%); /* Change colors as needed */
}

.contactustext {
  color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  white-space: nowrap;
}

.hiddennav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialicon {
  margin-top: 20px;
  font-size: 20px;
  color: #24415f;
  margin: 20px;
}
.desstyle {
  font-size: 15px;
  color: #24415f;
  margin: 0px;
}

.slidescontact {
  background-color: #fafcfd;
  width: 80% !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inputstyle {
  border: 1px solid white;
  background-color: #e7e7e7;
  width: 60% !important;
  height: 30px;
  border-radius: 5px;
  margin-top: 10px;
}
.inputstylearea {
  border: 1px solid gray;
  background-color: #e7e7e7;
  width: 60% !important;
  height: 70px;
  border-radius: 5px;
  margin-top: 10px;
}
.contactuss {
  /* margin-left: 50px !important; */
}
.contactusstext {
  margin-left: 20px;
}
.submitbutton {
  margin-top: 10px;
  color: white;
  background-color: #24415f;
  border: 1px solid white;
  width: 60%;
  border-radius: 5px;
}

/* @media (min-width: 768px) {
  .sideimageparahide {
    display: none;
  }
} */

/* Default styles for screens below 768px */
.sideimageparahide {
  display: block;
}

.sideimagepara {
  display: none;
}

/* Styles for screens 768px and above */
@media (min-width: 768px) {
  .sideimageparahide {
    display: none;
  }

  .sideimagepara {
    display: block;
  }
}
@media (max-width:990px) {

  .navstyleeside {
    font-size: 20px;
    font-weight: 700;
    color: #24415f !important;
    cursor: pointer;
    white-space: nowrap;
    margin-left: -29px;
  }
  
}

@media (max-width: 768px) {
  .Imagesstudyicon {
    width: 100%;
    height: 170px;
  }
  .buttonstyleapply {
    width: 40%;
    height: 40px;
    padding: 6px 0px 1px 0px;
    border-radius: 20px;

    /* align-items: center;
    display: flex;
    justify-content: center;
    height: 40px; */
    margin-top: 20px;
  }
  /* .sideimagepara {
    display: none;
  } */

  .logstyle {
    width: 140px;
    height: 70px;
  }
  .contactustext {
    font-size: 16px;
  }

  .navstylee {
    font-size: 10px;
    font-weight: 700;
    color: #24415f !important;
    cursor: pointer;
  }
  

  .buttonstylcontact {
    width: 25%;
    padding: 15px 5px 5px 5px;
    background: linear-gradient(80deg, #24415f 41%,#07b5cd 85%);

    height: 40px;
  }
  
}

@media (max-width: 490px) {
  .contactuss {
    margin-left: 0px !important;
  }
  .inputstyle {
    width: 100% !important;
  }
  .inputstylearea {
    width: 100% !important;
  }
  .mainSectionsec {
    font-size: 12px;
  }
  .topcent {
    margin-top: 0px !important;
  }
  .logstyle {
    width: 90px;
    height: 50px;
  }

  .buttonstyleapply {
    width: 40%;
    height: 35px;
    padding: 6px 0px 1px 0px;
    border-radius: 20px;

    /* align-items: center;
    display: flex;
    justify-content: center;
    height: 40px; */
    margin-top: 20px;
  }
  .errormsg {
    color: red !important;
  }
  .navstylee {
    font-size: 10px;
    font-weight: 500;
    color: #24415f !important;
    cursor: pointer;
    white-space: nowrap;
    margin-left: -5px;
  }

  .navstyleeside {
    font-size: 10px;
    font-weight: 500;
    color: #24415f !important;
    cursor: pointer;
    white-space: nowrap;
    margin-left: -23px;
  }

  .buttonstylcontact {
    width: 25%;
    padding: 15px 5px 5px 5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(80deg, #24415f 41%,#07b5cd 85%);

    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .contactustext {
    font-size: 12px;
  }
}

.Footerstyle {
  background: linear-gradient(
    90deg,
    rgba(23, 51, 94, 100) 14%,
    rgba(28, 42, 29, 100) 95%
  );

  .mainpowered {
    margin-top: 50px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
  .powered {
    margin-top: 40px;
    color: #ffffff;
    font-size: 12px;
  }
}

.footericon {
  font-size: 30px;
  color: white;
}

.footericonloco {
  font-size: 20px;
  color: white;
}

.footericonlocosocial {
  font-size: 20px;
  color: white;
  margin: 20px;
}

.footerstext {
  margin-left: 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.footerstextt {
  margin-left: 10px;
  color: white;
  margin-top: 0px;
  cursor: pointer;
}
.footerstextt:hover {
  color: greenyellow;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.footerstext:hover {
  color: greenyellow;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.headermain {
  background-color: #fcfefd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9) !important;
}
.carousel-containerjob {
  padding: 20px;
}

.carousel-itemjob  {
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  margin:0px; /* Space between items */
}




.carousel-containerjob  {
  padding: 0px !important;
 

}

.carouselitemjob  {
  border-radius: 5px !important;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  margin: 15px; /* Space between items */
  /* //background-color: #07b5cd; */
  
}



.carouselitemjob:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.carousel-imgjob  {
  width: 100%;
  height: 250px;
  
  object-fit: cover;

}


.carousel-titlejob  {
 
  font-size: 18px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  
  text-align: center;
  
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(0);
}
.desjobmain{
  padding-left: 10px;
  padding-right: 10px;

  text-align: center;
}



.desjob {
  
  height: 100px;
  overflow-y: auto;
  text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.desjob::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.desjob {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}





.carousel-containerTestimonial {
 padding-bottom: 20px;
  
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the image horizontally */
  justify-content: center; /* Centers the image vertically */
  text-align: center; /* Centers the text below the image */
}
.bounce{
  transition: transform 0.3s ease-in-out, 

}

.bounce:hover {
  transform: scale(1.05);

}
.carouselitemTestimonial {
  border-radius: 5px !important;
  background-color:white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;

  
  
  margin: 15px; /* Space between items */
}

.carouselitemTestimonial:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.carousel-imgTestimonial {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%; /* Make the image circular */
  margin-top: 20px;
}



.carousel-titleTestimonial {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  
  text-align: center;
  
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* transform: translateX(0); */
}
.heightty {
  height: 195px;
  overflow-y: scroll;
}

/* For Chrome, Safari, and Edge */
.heightty::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.heightty {
  scrollbar-width: none; /* Firefox 64 and later */
}

/* For Internet Explorer and Edge Legacy */
.heightty {
  -ms-overflow-style: none; /* IE and Edge Legacy */
}


.carousel-titleTestimonialsub {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  
  color: rgb(0, 0, 0);
  
  text-align: center;
  
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* transform: translateX(0); */
}
.carouselcontainertest{
  background-color:red;
  padding-left: 110px;
  padding-right:110px
}
.dessub{
  text-align: center;
 
  margin-top: 10px;
  padding: 0 15px;
}

.carouselitemTestimonial:hover .carousel-titleTestimonial {
/*   
  transform: translateX(100%); */
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 10;
  cursor: pointer;
}

.custom-left-arrow {
  left: -50px; /* Adjust as needed */
}

.custom-right-arrow {
  right: -50px; /* Adjust as needed */
}


.slick-next:before {
  color: orange !important;
 
}
.slick-prev:before{
  
  color: orange !important;
  margin-right: 80px !important;
}

.partner-bg-image {
  /* background-color: rgb(32, 29, 29); */
  height: 100px ;
  width: 90% !important;
  border-radius: 10px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  
  

}

.partner-image {
  height: 100px;
  width: 100%;
  object-fit: contain;
  /* /background-color: red !important; */
  display: flex;
  
}

.slide-part {
 
  border-radius: 10px;

}


.boxservices{
  background: linear-gradient(80deg, #2970a8 41%,#07b5cd 85%);
  padding: 20px;
  border-radius: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(0);
}
.boxservices:hover{
  background-color:#07b5cd;
  transform: translateX(10%);
}
.footerstextservices {
  margin-left: 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}




@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.text-animation {
  animation: slideUp 2s ease-in-out;
}
.linearbg{
  background: linear-gradient(80deg, #24415f 41%,#07b5cd 85%);

}



@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-right-to-left {
  animation: slideRightToLeft 1s ease-in-out forwards;
}

.hidden {
  opacity: 0;
}
